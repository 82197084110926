import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';

export interface ButtonOptions {
  disabled?: boolean;
  disabledPlaceholder?: string;
  icon?: string;
  label?: string;
  showButton?: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatFormFieldModule, MatIconModule, RouterModule, MatProgressBarModule, MatTooltipModule, NgClass],
  selector: 'ingenix-header',
  standalone: true,
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Output() handleHeaderButtonClicked = new EventEmitter<void>();
  @Output() handleSecondaryButtonClicked = new EventEmitter<void>();
  @Output() handleThirdButtonClicked = new EventEmitter<void>();
  @Input() public isLoading = signal<boolean>(false);
  @Input() public paddingTop: boolean = false;
  @Input() public primaryButtonOptions = signal<ButtonOptions>({ showButton: false });
  @Input() public routerLink?: string[];
  @Input() public secondaryButtonOptions = signal<ButtonOptions>({ showButton: false });
  @Input() public showSecondaryButton: boolean = true;
  @Input() public showThirdButton: boolean = true;
  @Input() public thirdButtonOptions = signal<ButtonOptions>({ showButton: false });
  @Input() public title!: string;

  constructor(private readonly router: Router) {}

  public onButtonClick() {
    if (this.routerLink) {
      this.router.navigate(this.routerLink);
    } else {
      this.handleHeaderButtonClicked.next();
    }
  }

  public onSecondaryButtonClick() {
    this.handleSecondaryButtonClicked.next();
  }

  public onThirdButtonClick() {
    this.handleThirdButtonClicked.next();
  }
}
