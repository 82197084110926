<header [ngClass]="{ paddingTop: 'header--padding-top' }">
  <h2>{{ title }}</h2>
  <div class="header__button">
    @if (thirdButtonOptions().showButton) {
      <button
        class="secondary-button"
        mat-stroked-button
        (click)="onThirdButtonClick()"
        [disabled]="thirdButtonOptions().disabled"
        [matTooltip]="thirdButtonOptions().disabled ? thirdButtonOptions().disabledPlaceholder ?? '' : ''"
      >
        <mat-icon class="material-symbols-outlined">{{ thirdButtonOptions().icon }}</mat-icon>
        {{ thirdButtonOptions().label }}
      </button>
    }
    @if (secondaryButtonOptions().showButton) {
      <button
        class="secondary-button"
        mat-stroked-button
        (click)="onSecondaryButtonClick()"
        [disabled]="secondaryButtonOptions().disabled"
        [matTooltip]="secondaryButtonOptions().disabled ? secondaryButtonOptions().disabledPlaceholder ?? '' : ''"
      >
        <mat-icon class="material-symbols-outlined">{{ secondaryButtonOptions().icon }}</mat-icon>
        {{ secondaryButtonOptions().label }}
      </button>
    }
    @if (primaryButtonOptions().showButton) {
      <button
        mat-raised-button
        color="primary"
        (click)="onButtonClick()"
        [disabled]="primaryButtonOptions().disabled"
        [matTooltip]="primaryButtonOptions().disabled ? primaryButtonOptions().disabledPlaceholder ?? '' : ''"
      >
        <mat-icon class="material-symbols-outlined">{{ primaryButtonOptions().icon }}</mat-icon
        >{{ primaryButtonOptions().label }}
      </button>
    }
  </div>
</header>
@if (isLoading()) {
  <mat-progress-bar color="primary" [mode]="'indeterminate'"></mat-progress-bar>
}
